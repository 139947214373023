body {
  -webkit-print-color-adjust: exact;
}

.content-block__content-container {
  padding: rhythm(2);
}

.form-cta {
  display: none;
}

.js-fade-on-scroll {
  opacity: 1 !important;
}

.js-slide-on-scroll {
  margin-bottom: 0 !important;
}

.primary-header {
  position: relative;
  width: 100%;
}

.primary-nav {
  display: none;
}
